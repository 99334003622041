import Vue from 'vue'
import Vuex from 'vuex'
import Service from '../utils/services'

Vue.use(Vuex)

// 获取公司信息
export const getBasic = (succ, fail) => {
  Service.post('/siteConfig/get', { id: window.siteConfigId }).then(res => {
    if (res.data.code === 0) {
      succ(res.data)
    } else {
      fail()
    }
  }).catch(error => {
    fail()
    console.info(error)
  })
}

// 获取banner图片信息
export const getBannerImg = (succ, fail) => {
  Service.post('/banner/get', { id: window.siteBannerId }).then(res => {
    if (res.data.code === 0) {
      succ(res.data)
    } else {
      fail()
    }
  }).catch(error => {
    fail()
    console.info(error)
  })
}

// 获取用户协议信息
export const getAgree = (succ, fail) => {
  Service.post('/information/get', { id: window.siteAgreeId }).then(res => {
    if (res.data.code === 0) {
      succ(res.data)
    } else {
      fail()
    }
  }).catch(error => {
    fail()
    console.info(error)
  })
}

// 获取项目列表信息
export const getProjectData = (succ, fail) => {
  Service.post('/information/list', { type: 6, status: 1, pageNo: window.projectNum, pageSize: 4, sortField: 'id', sortOrder: 'asc' }).then(res => {
    if (res.data.code === 0) {
      succ(res.data)
    } else {
      fail()
    }
  }).catch(error => {
    fail()
    console.info(error)
  })
}

// 获取项目详情信息
export const getProjectDetail = (obj, succ, fail) => {
  Service.post('/information/get', obj).then(res => {
    if (res.data.code === 0) {
      succ(res.data)
    } else {
      fail()
    }
  }).catch(error => {
    fail()
    console.info(error)
  })
}

// 用户信息
export const getAgentInfo = (obj, succ, fail) => {
  Service.post('/siteUser/getInfo', obj).then(res => {
    if (res.data.code === 0) {
      succ(res.data)
    } else {
      fail()
    }
  }).catch(error => {
    fail()
    console.info(error)
  })
}

// 修改用户信息
export const getAgentEdit = (obj, succ, fail) => {
  Service.post('/siteUser/edit', obj).then(res => {
    if (res.data.code === 0) {
      succ(res.data)
    } else {
      fail()
    }
  }).catch(error => {
    fail()
    console.info(error)
  })
}

// 修改用户密码
export const getEditPassword = (obj, succ, fail) => {
  Service.post('/siteUser/editPassword', obj).then(res => {
    if (res.data.code === 0) {
      succ(res.data)
    } else {
      fail()
    }
  }).catch(error => {
    fail()
    console.info(error)
  })
}

// 用户退出
export const getAgentLogout = (succ, fail) => {
  Service.post('/siteUser/logout').then(res => {
    if (res.data.code === 0) {
      succ(res.data)
    } else {
      fail()
    }
  }).catch(error => {
    fail()
    console.info(error)
  })
}
